export default [{
    title: 'Reporteria',
    icon: 'PieChartIcon',
    children: [
		{
			title: 'Evaluaciones de Criticidad',
			route: 'report-criticality-evaluations',
			action: 'read',
			resource: 'reporters'
    	}, 
		{
			title: 'Evaluaciones de Desempeño',
			route: 'report-performance-evaluations',
			action: 'read',
			resource: 'reporters'
		}
	]
}]