export default [{
    title: 'Contratos',
    icon: 'FileTextIcon',
    children: [
        {
            title: 'Lista',
            route: 'contracts-list',
            action: 'read',
		    resource: 'contracts'
        }, 
        {
            title: 'Nuevo',
            route: 'contracts-create',
            action: 'create',
		    resource: 'contracts'
        }
    ]
}];