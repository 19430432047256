export default [{
    title: 'Evaluaciones',
    icon: 'BookmarkIcon',
    children: [
        {
            title: 'Criticidades',
            route: 'criticalities-list',
            action: 'read',
		    resource: 'criticalities'
        }, 
        {
            title: 'Desempeños',
            route: 'performances-list',
            action: 'read',
		    resource: 'perfomances'
        }, 
    ]
}];